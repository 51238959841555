import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SidebarStatus } from '../../models/sidebar.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() sidebarStatus: SidebarStatus;
  @Output() sidebarStatusEvent = new EventEmitter<SidebarStatus>();
  public active: string;
  private subscriptions: Array<Subscription>;

  constructor(private routeService: Router, private activatedRoute: ActivatedRoute) {
    this.active = this.routeService.url.split("?")[0].split("/")[2];
    this.subscriptions = [];
  }
  
  ngOnInit(): void {
    const o = this.routeService.events.subscribe((val) => {
      if (val instanceof NavigationEnd){
        this.active = val.url.split("?")[0].split("/")[2];
      }
    });
    this.subscriptions.push(o);
  }

  public toggleSidebar(){
    this.sidebarStatus.open = !this.sidebarStatus.open;
    this.sidebarStatusEvent.emit(this.sidebarStatus);
  }


  ngOnDestroy(): void{
    this.subscriptions.forEach(o=>{o.unsubscribe()})
  }
}
