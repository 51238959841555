import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginBody } from '../private/models/auth-user.model';
import { AuthService } from '../private/services/auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss','../private/layout/header/header.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginBody: LoginBody;
  public error : boolean = false;
  private subscriptions: Array<Subscription>;

  constructor(private authService: AuthService, private router: Router) {

    this.loginBody = {
      username: '',
      password: ''
    };
    this.subscriptions = [];
  }

  ngOnInit(): void {
  }

  public submit(form: NgForm){
    console.log('hola????');
    if (this.loginBody.username && this.loginBody.password){
      const auth$ = this.authService.login(this.loginBody).subscribe(
        {
          next: (response)=>{
            this.router.navigate(['/private/dashboard']);
          },
          error: (err)=>{
            console.log(err);
            this.error=true;
          }
        });
      this.subscriptions.push(auth$)

    }
  }
  ngOnDestroy(): void{
    this.subscriptions.forEach(o=>o.unsubscribe());
  }
}
