import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
interface UserMenu {
    open : boolean
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public username = 'User'
  public menu: UserMenu = {
    open: false
  }
  constructor(private authService: AuthService, private router: Router) {
    this.menu.open = false;

  }

  ngOnInit(): void {
    this.authService.getUserLogged().subscribe((value) => {
      console.log(value)
      this.username = value.username || 'User'
    })
  }


  public logout(){
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
