import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CheckedEvents } from 'src/app/private/layout/modal-event-selector/models/checkedevents.model';
import { UtilsService } from 'src/app/private/services/utils.service';
import { Upgrade } from '../../models/upgrade.model';
import { UpgradeService } from '../../services/upgrade.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  public item: Upgrade;
  public postFile: HTMLInputElement;
  public postImage: File;
  public imagePreview: any;
  private closeReason: 'success' | 'cancel';
  private checked_events: CheckedEvents;
  public hashValidator = {
    name: false,
    description: false,
    buyerTypeName: false,
    buyerTypeId: false,
    price: false,
  }

  constructor(private bsModalRef: BsModalRef,
    private modalOptions: ModalOptions,
    private upgradeService: UpgradeService,
    public utils: UtilsService,) {
  }

  ngOnInit(): void {
    this.item = this.modalOptions.initialState['item'] as Upgrade;
    this.imagePreview = null;
  }

  public onDismiss(reason: 'success' | 'cancel'){
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm(){
    console.log(this.checked_events)
    console.log(this.item)
    if(this.checkInputs(this.item)) return;
    const update$ = this.upgradeService.update$(this.item, this.postImage, this.checked_events.selected.ids).subscribe({
      next:(response: Upgrade)=>{
        update$.unsubscribe()
        console.log(response);
        this.onDismiss('success')
      },
      error: (err)=>{
        console.log(err);
      }
    });
  }

  public onFileChange(event){
    this.postImage = event.target.files[0];
    var reader = new FileReader();
    this.imagePreview = null;
    reader.readAsDataURL(event.target.files[0]);
		reader.onload = (_event) => {
			this.imagePreview = reader.result;
		}
  }

  public getCheckedEvents($event){
    this.checked_events = $event;
  }

  public removeImage(){
    this.item.url_image = null
    this.postImage = null
    this.imagePreview = null
  }

    // check specified fields and change value at hasValidator
  // help to detect wich inputs have bad values
  private checkInputs(instance: Upgrade): boolean {
    let hasErrors = false
    // Reset validator on every check
    Object.keys(this.hashValidator).forEach((value) => {
      this.hashValidator[value] = false;
    })
    if (this.utils.isEmpty(instance.buyerTypeName)) {
      this.hashValidator.buyerTypeName = true;
      hasErrors = true
    }
    if (this.utils.isEmpty(instance.name)) {
      this.hashValidator.name = true;
      hasErrors = true
    }
    if (this.utils.isEmpty(instance.description)) {
      this.hashValidator.description = true;
      hasErrors = true
    }
    if (!this.utils.onlyHasNumbers(instance.buyerTypeId)) {
      this.hashValidator.buyerTypeId = true;
      hasErrors = true
    }
    if(instance.price <= 0) {
      this.hashValidator.price = true
      hasErrors = true
    }

    return hasErrors
  }
}
