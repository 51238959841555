import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private onlyNumbers = new RegExp('^[0-9]+$');

  constructor() { }

  /**
   * Check using RegExp if string has only numbers
   * @param {string} value - item to test
   * @returns {boolean} - true if only has numbers, otherwise false
   */
  public onlyHasNumbers(value): boolean {
    return this.onlyNumbers.test(value)
  }

  /**
   * Checks if a JavaScript value is empty
   * @example
   *    isEmpty(null); // true
   *    isEmpty(undefined); // true
   *    isEmpty(''); // true
   *    isEmpty([]); // true
   *    isEmpty({}); // true
   * @param {any} value - item to test
   * @returns {boolean} true if empty, otherwise false
   */
  public isEmpty(value): boolean {
    return (
      value === null || // check for null
      value === undefined || // check for undefined
      value === '' || // check for empty string
      (Array.isArray(value) && value.length === 0) || // check for empty array
      (typeof value === 'object' && Object.keys(value).length === 0) // check for empty object
    );
  }
}
