import { Event } from "../../events/models/event.model";

export interface Adlink{
    id?: number,
    name?: string,
    url_image?: string,
    link?: string,
    title?: string,
    active?: boolean,
    events?: Array<Event>
}

export interface adLinkList {

    meta: {
      total: number
      per_page: number
      current_page: number
      last_page: number
      first_page: number
      first_page_url: string
      last_page_url: string
      next_page_url: string
      previous_page_url: any
    },
    data: Array<Adlink>

  }
