<div class="modal-container">
    <div class="data-container">
        <div class="title-wrapper">
            <p>Edit Upsell</p>
            <button type="button" class="btn-modal-header" (click)="onDismiss('cancel')" data-dismiss="modal">
                <span class='ti ti-close'></span>
            </button>
        </div>
        <div class="content-wrapper">
            <div class="mt-4">
                <div class="input-line">
                    <div>
                        <span><b>Name</b></span>
                    </div>
                    <div>
                        <input type="text" [(ngModel)]="item.name" [class.invalid-input]="hashValidator.name">
                        <span class="small error-color" *ngIf="hashValidator.name">This entry is mandatory</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Description</b></span>
                    </div>
                    <div>
                        <textarea [(ngModel)]="item.description" [class.invalid-input]="hashValidator.description"></textarea>
                        <span class="small error-color" *ngIf="hashValidator.description">This entry is mandatory</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Goal</b></span>
                    </div>
                    <div>
                        <input type="number" [(ngModel)]="item.goal" [class.invalid-input]="hashValidator.goal">
                        <span class="small error-color" *ngIf="hashValidator.goal">This entry is invalid</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Min. Seats to show</b></span>
                    </div>
                    <div>
                        <input type="number" [(ngModel)]="item.minSeatsToShow" [class.invalid-input]="hashValidator.minSeatsToShow">
                        <span class="small error-color" *ngIf="hashValidator.minSeatsToShow">This entry is invalid</span>
                    </div>
                </div>
                <div class="input-line">
                    <div>
                        <span>
                            <b>Visibility</b>
                        </span>
                    </div>
                    <div>
                        <select [(ngModel)]="item.active">
                            <option value="true">On</option>
                            <option value="false">Off</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4 ms-4">
                <app-modal-event-selector (checkedevents)="getCheckedEvents($event)" [itemEvents]="item.events"></app-modal-event-selector>
            </div>
        </div>
        <div class="btns-containers">
            <button class="default-4" (click)="onDismiss('cancel')">Cancel</button>
            <button class="default-1" (click)="onConfirm()">Save & Publish</button>
        </div>
    </div>
</div>
