import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { PaginationComponent } from '../layout/pagination/pagination.component';
import { CreateComponent } from './modals/create/create.component';
import { DeleteComponent } from './modals/delete/delete.component';
import { EditComponent } from './modals/edit/edit.component';
import { Adlink } from './models/adlink.model';
import { AdlinkService } from './services/adlink.service';

@Component({
  selector: 'app-adlink',
  templateUrl: './adlink.component.html',
  styleUrls: ['./adlink.component.scss']
})
export class AdlinkComponent implements OnInit, OnDestroy  {

  public data: Array<Adlink>;
  public total_items_in_db: number;
  public items_per_page: number;
  bsModalRef?: BsModalRef;
  public current_page: number;
  @ViewChild('pagination') pagination: PaginationComponent;
  public subscriptions: Array<Subscription>;
  public refreshing: boolean;

  constructor(private modalService: BsModalService,
    private adLinkService: AdlinkService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
      this.data = [];
      this.subscriptions = [];
      this.refreshing = false;
      this.total_items_in_db = 0;
      this.items_per_page = 0;
      this.current_page = this.activatedRoute.snapshot.queryParams['page'];
  }

  ngOnInit(): void {
    let resolved = this.activatedRoute.snapshot.data['api'];
    this.data = resolved.data;
    this.total_items_in_db = resolved.meta.total;
    this.items_per_page = resolved.meta.per_page;

    const queryparams$ = this.activatedRoute.queryParams.subscribe(params => {
      let resolved = this.activatedRoute.snapshot.data['api'];

      if(!params['page'] || params['page']>resolved.meta.last_page){
        this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: {page:1}});
      }

      if(params['page']){

        this.current_page = params['page'];
        this.data = resolved.data;
        this.total_items_in_db = resolved.meta.total;
        this.items_per_page = resolved.meta.per_page;
        if(this.pagination){
          this.pagination.refresh(this.current_page, this.total_items_in_db, this.items_per_page );
        }
      }
    });
    this.subscriptions.push(queryparams$);
  }

  ngOnDestroy(): void{
    this.subscriptions.forEach(o=>o.unsubscribe());
  }

  public openModalDelete(id){
    const initialState: object = {
      id: id
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'delete-upsell'
    }
    this.bsModalRef = this.modalService.show(DeleteComponent, modalConfig);
    this.refreshListOnHide(1);

  }

  public openModalEdit(item: Adlink){
    const initialState: object = {
      item: JSON.parse(JSON.stringify(item))
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'edit-upsell'
    }
    this.bsModalRef = this.modalService.show(EditComponent, modalConfig);
    this.refreshListOnHide(this.current_page);

  }

  public openModalCreate(){
    const initialState: object = {};
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'create-upsell'
    }
    this.bsModalRef = this.modalService.show(CreateComponent, modalConfig);
    this.refreshListOnHide(1);
  }

  private refreshListOnHide(page?:string|number){
    const onH$ = this.bsModalRef.onHide.subscribe((response)=>{
      if(this.bsModalRef.content.closeReason == 'success'){
        this.getall(page);
      }
    })
    this.subscriptions.push(onH$);
  }

  private getall(page?){
    if(!page) page = this.current_page;

    const all$ = this.adLinkService.getAll$(page).subscribe(
      {
        next:(res: any)=>{
          this.refreshing = false;
          this.data = res.data;
          this.total_items_in_db = res.meta.total;
          this.items_per_page = res.meta.per_page;
          if(this.pagination){
            this.pagination.refresh(this.current_page,this.total_items_in_db,this.items_per_page);
          }
          if(this.current_page>res.meta.last_page){
            this.current_page =page;
          }
          this.router.navigate(['/private/adlink'],{ queryParams: {page:this.current_page}});
        },
        error: (err)=>{
          console.log(err);
        }
    });
    this.subscriptions.push(all$);
  }

  public toggleActive(item: Adlink){
    item.active = !item.active;
    const update$ = this.adLinkService.update$(item).subscribe(res=>{
      console.log(res);
    });
    this.subscriptions.push(update$);
  }

  public pageChanged($event){
    this.router.navigate(['/private/adlink'],{ queryParams: {page:$event.page}});
  }
}
