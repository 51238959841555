import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteComponent } from './modals/delete/delete.component';
import { UpgradeComponent } from './upgrade.component';
import { CreateComponent } from './modals/create/create.component';
import { EditComponent } from './modals/edit/edit.component';
import { FormsModule } from '@angular/forms';
import { ModalEventSelectorModule } from '../layout/modal-event-selector/modal-event-selector.module';
import { PaginationModule } from '../layout/pagination/pagination.module';

@NgModule({
  declarations: [
    UpgradeComponent,
    DeleteComponent,
    CreateComponent,
    EditComponent
  ],
  imports: [
    CommonModule,
    PaginationModule,
    FormsModule,
    ModalEventSelectorModule
  ],
  exports: [
    UpgradeComponent
  ]
})
export class UpgradeModule { }
