<div class="sidebar-wrapper" [ngClass]="{'sidebar-open':sidebarStatus.open}">
    <div class="sidebar-item">
        <a (click)="toggleSidebar()" title='Menu'>
            <div class="left-side">
                <i class="ti ti-menu"></i>
            </div>
            <div class="right-side">
                <span>Menu</span>
            </div>
        </a>
    </div>
    <div class="sidebar-item">
        <a [routerLink]="['/private/dashboard']" title='Dashboard' [ngClass]="{'active':this.active==='dashboard'}">
            <div class="left-side">
                <i class="ti ti-home"></i>
            </div>
            <div class="right-side">
                <span>Dashboard</span>
            </div>
        </a>
    </div>
    <div class="sidebar-item">
        <a [routerLink]="['/private/transactions']" [queryParams]="{page: '1'}" title='Transactions management' [ngClass]="{'active':this.active==='transactions'}">
            <div class="left-side">
                <i class="ti ti-credit-card"></i>
            </div>
            <div class="right-side">
                <span>Transaction Management</span>
            </div>
        </a>
    </div>
    <div class="sidebar-item">
        <a [routerLink]="['/private/events']" [queryParams]="{page: '1'}" title='Events management' [ngClass]="{'active':this.active==='events'}">
            <div class="left-side">
                <i class="ti ti-cup"></i>
            </div>
            <div class="right-side">
                <span>Events Management</span>
            </div>
        </a>
    </div>
    <div class="sidebar-item">
        <a [routerLink]="['/private/upsell']" [queryParams]="{page: '1'}" title='Upsell management' [ngClass]="{'active':this.active==='upsell'}">
            <div class="left-side">
                <img src="/assets/icons/gift.svg">
            </div>
            <div class="right-side">
                <span>Upsell Management</span>
            </div>
        </a>
    </div>
    <div class="sidebar-item">
        <a [routerLink]="['/private/upgrade']" [queryParams]="{page: '1'}" title='Upgrade management' [ngClass]="{'active':this.active==='upgrade'}">
            <div class="left-side">
                <img src="/assets/icons/upgrade.svg">
            </div>
            <div class="right-side">
                <span>Upgrade Management</span>
            </div>
        </a>
    </div>
    <div class="sidebar-item">
        <a [routerLink]="['/private/adlink']"  [queryParams]="{page: '1'}" title='Adlink management' [ngClass]="{'active':this.active==='adlink'}">
            <div class="left-side">
                <img src="/assets/icons/tag.svg">
            </div>
            <div class="right-side">
                <span>AdLink Management</span>
            </div>
        </a>
    </div>
</div>