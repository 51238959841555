import { Event } from "../../events/models/event.model"

export interface Transaction{
    id: number
    status: string
    seats: any | Array<Array<string>>
    original_buyer_type_id: number
    buyer_type_id: number
    patronId: any
    patronFullName: any
    patronEmail: any
    patronPhone: string | null
    event_id: number
    created_at: string
    updated_at: string
    tdc_transaction: TdcTransaction
    event: Event
}
export interface TdcTransaction {
    id: number
    balance: number
    serviceCharges: number
    cart_id: number
    cart_etag: string
    tdc_transaction_id: number
    transaction_id: number
    credit_available: number
    total_sales_revenue: number
    receivable_reduction: number
    cartExpirationSeconds: number
    hasInsuranceRainOut: boolean
    insueranceRainOutPrice: any
    originalBalance: number
    originalTotalSalesRevenue: number
}

export interface TransactionStatusHashed{
    [key:string]: TransactionStatus
}


export interface TransactionStatus{
    id: string,
    name: string
}

export interface TransactionStatusObj {
    items: TransactionStatusHashed;
    list: Array<TransactionStatus>
}

export interface SearchQueryParams{
    params:{
        status: string,
        accountId: string,
        fullName: string,
        dateFrom: string,
        dateTo: string,
        event: string,
    },
    applied: boolean
  }
