import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { EventsService } from '../events/services/events.service';
import { PaginationComponent } from '../layout/pagination/pagination.component';
import { ViewComponent } from './modals/view/view.component';
import { SearchQueryParams, Transaction, TransactionStatusObj } from './models/transaction.model';
import { TransactionService } from './services/transactions.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnDestroy, AfterViewInit {
  public data: Array<Transaction>;
  public total_items_in_db: number;
  public items_per_page: number;
  private subscriptions: Array<Subscription>;
  private bsModalRef?: BsModalRef;
  public transactionsStatus: TransactionStatusObj
  public search: SearchQueryParams;
  public current_page: number;
  @ViewChild('pagination') pagination: PaginationComponent;
  //ng multiselect
  public eventList:Array<{
    id: number,
    description: string
  }>
  public dropdownSettings: any = {};
  public selectedItems = [];

  constructor(private transactionService: TransactionService,
            private activatedRoute: ActivatedRoute,
            private eventsService: EventsService,
            private router: Router,
            private modalService: BsModalService) {
    this.subscriptions = [];
    this.initializeSearch();
    
  }
  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    //Resolve all events
    let allevents = this.activatedRoute.snapshot.data['events'];
    //ng multiselect: Create list
    this.eventList = allevents.data.map(i=>{ return { code: i.code, description: i.description+' / '+i.code } });
    
    this.transactionsStatus = {
      items: {
        'PH': {id: 'PH',name:'Pending Hold'},
        'CA': {id: 'CA',name:'Cancelled'},
        'OK': {id: 'OK',name:'Confirm'}
      },
      list: [
        {id: 'PH',name:'Pending Hold'},
        {id: 'CA',name:'Cancelled'},
        {id: 'OK',name:'Confirm'}
      ]
    }


    let resolved = this.activatedRoute.snapshot.data['api'];
    this.data = resolved.data;
    this.total_items_in_db = resolved.meta.total;
    this.items_per_page = resolved.meta.per_page;

    const queryparams$ = this.activatedRoute.queryParams.subscribe(params => {
      this.current_page = params['page'];
      let resolved = this.activatedRoute.snapshot.data['api'];
/*       if(resolved.meta.current_page !== this.current_page){
        this.refresh();
      } */

      if(!params['page'] || params['page']>resolved.meta.last_page){
        this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: {page:1}});
      }

      if(params['page']){
        this.current_page = params['page'];
        this.data = resolved.data;
        this.total_items_in_db = resolved.meta.total;
        this.items_per_page = resolved.meta.per_page;
        if(this.pagination){
          this.pagination.refresh(this.current_page, this.total_items_in_db, this.items_per_page );
        }
      }
    });
    this.subscriptions.push(queryparams$);

  }

  ngOnDestroy(){
    this.subscriptions.forEach(o=>o.unsubscribe());
  }

  public refresh(){

    const all$ = this.transactionService.getAll$(this.current_page).subscribe(res=>{
      this.data = res.data;
      this.total_items_in_db = res.meta.total;
      this.items_per_page = res.meta.per_page;
      if(this.pagination){
        this.pagination.refresh(this.current_page,this.total_items_in_db,this.items_per_page);
      }
      if(this.current_page>res.meta.last_page){
        this.current_page = res.meta.last_page;
      }
      this.router.navigate(['/private/transactions'],{ queryParams: {page:this.current_page}});
    });
    this.subscriptions.push(all$);
  }

  private initializeSearch(){
    this.search = {
      params : {
        status: '',
        event: '',
        accountId:'',
        fullName: '',
        dateFrom:'',
        dateTo:''
      },
      applied: false
    }
    this.transactionService.search = JSON.parse(JSON.stringify(this.search));
    //NG MULTISELECT
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'code',
      textField: 'description',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.selectedItems = [];

  }

  public openModalEdit(item: Transaction){
    const initialState: object = {
      item: item,
      transactionStatus: this.transactionsStatus
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'edit-upsell'
    }
    this.bsModalRef = this.modalService.show(ViewComponent, modalConfig);
  }

  public submit(){
    this.search.applied = true;
    this.transactionService.search = JSON.parse(JSON.stringify(this.search));
    if(this.current_page != 1){
      this.router.navigate(['/private/transactions'],{ queryParams: {page:1}});
    }else{
      console.log(this.search)
      const search$ = this.transactionService.getAll$(1,this.search).subscribe(res=>{
        this.data = res.data;
        console.log(this.data);
        this.total_items_in_db = res.meta.total;
        this.items_per_page = res.meta.per_page;
        this.current_page = 1;
        if(this.pagination){
          this.pagination.refresh(this.current_page,this.total_items_in_db,this.items_per_page);
        }
      });
      this.subscriptions.push(search$)
    }

  }

  public reset(){
    this.initializeSearch();

    if(this.current_page != 1){
      this.router.navigate(['/private/transactions'],{ queryParams: {page:1}});
    }else{
      const search$ = this.transactionService.getAll$(1).subscribe(res=>{
        this.data = res.data;
        this.total_items_in_db = res.meta.total;
        this.items_per_page = res.meta.per_page;
        this.current_page = 1;
        if(this.pagination){
          this.pagination.refresh(this.current_page,this.total_items_in_db,this.items_per_page);
        }
      });
      this.subscriptions.push(search$)
    }
  }

  public ondropdownSelect(item){
    if(item && item.code){
      this.search.params.event = item.code;
    }
    return;
  }
  public ondropdownUnSelect(){
    this.search.params.event = '';
    return;
  }
}
