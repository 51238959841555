import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild{

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            let a = this.authService.checkTokenExpiration();
            if (this.authService.checkTokenExpiration()) {
            this.authService.checkCurrentuser();
            this.router.navigate(['/login']);
            return false;
        } else {
            return true;
        }
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            if (this.authService.checkTokenExpiration()) {
                this.authService.checkCurrentuser();
                this.router.navigate(['/login']);
                return false;
            } else {
                return true;
            }
    }
}