<div class="modal-container">
    <div class="data-container">
        <div class="title-wrapper">
            <p>Transactions Details</p>
            <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">
                <span class='ti ti-close'></span>
            </button>
        </div>
        <div class="content-wrapper mt-4" *ngIf="data">
            <div class="content-wrapper-col">
                <div  class="input-line">
                    <div>
                        <span><b>Patron Email</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>{{data.patronEmail}}</span>
                    </div>
                </div>
                <div class="input-line">
                    <div>
                        <span><b>Patron Full Name</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>{{data.patronFullName}}</span>
                    </div>
                </div>
                <div class="input-line">
                  <div>
                      <span><b>Patron Phone</b></span>
                  </div>
                  <div class="input-line-value">
                      <span *ngIf="data.patronPhone;else nophone">{{data.patronPhone}}</span>
                      <ng-template #nophone>
                        <span>None</span>
                      </ng-template>
                  </div>
              </div>
                <div  class="input-line">
                    <div>
                        <span><b>Seats ({{data.seats.length}})</b></span>
                    </div>
                    <div class="input-line-value seats-wrapper" *ngIf="data.seats.length">
                        <table class="table">
                            <thead>

                                <tr>
                                    <th>Section</th>
                                    <th >Row</th>
                                    <th>Seat</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of data.seats">
                                    <td>{{s[0]}}</td>
                                    <td>{{s[1]}}</td>
                                    <td>{{s[2]}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Buyer Type</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>{{data.buyer_type_id}}</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Status</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>{{transactionStatus.items[data.status.toUpperCase()].name}}</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Original Buyer Type</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>{{data.original_buyer_type_id}}</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Date</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>{{data.created_at |date}}</span>
                    </div>
                </div>
            </div>
            <div class="content-wrapper-col">
                <div  class="input-line">
                    <div>
                        <span><b>Balance</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>$ {{data.tdc_transaction.balance}}</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Cart ETAG</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>{{data.tdc_transaction.cart_etag}}</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Credit Available</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>$ {{data.tdc_transaction.credit_available}}</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Insurance Rain Out</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>{{data.tdc_transaction.hasInsuranceRainOut}}</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Insurance Rain Out Price</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>$ {{data.tdc_transaction.insueranceRainOutPrice}}</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Original Balance</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>$ {{data.tdc_transaction.originalBalance}}</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Original Total Sales Revenue</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>$ {{data.tdc_transaction.originalTotalSalesRevenue}}</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Total Sales Revenue</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>$ {{data.tdc_transaction.total_sales_revenue}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="btns-containers">
            <button class="default-1" (click)="onConfirm()">Close</button>
        </div>
    </div>
</div>
