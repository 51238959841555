import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Upsell } from "../models/upsell.model";

@Injectable({
    providedIn: 'root'
})
export class UpsellService{

    private apiRoot = '/bo/event-upsell';

    constructor(private http: HttpClient){}

    public getAll$(page?:number): Observable<any>{
        let query = '';
        if(page){
            query='?page='+page;
        }
        return this.http.get<any>(environment.apiURl+`${this.apiRoot}${query}`);
    }
    public get$(id:number): Observable<any>{
        return this.http.get<any>(environment.apiURl+`${this.apiRoot}/${id}`);
    }
    public create$(body:Upsell, nm: Array<string|number>): Observable<any>{
        let formData:FormData = new FormData();

        formData.append('active',body.active.toString());
        formData.append('name',body.name);
        formData.append('description',body.description);
        formData.append('minSeatsToShow', `${body.minSeatsToShow}`);
        formData.append('goal', `${body.goal}`);
        if(nm){
            if(nm.length){
                nm.forEach(i=>{
                    formData.append('event[]',i.toString())
                })
            }else{
                formData.append('event[]','')

            }
        }

        return this.http.post<any>(environment.apiURl+`${this.apiRoot}`,formData);
    }
    public delete$(id:number): Observable<any>{
        return this.http.delete<any>(environment.apiURl+`${this.apiRoot}/${id}`);
    }
    public update$(body:Upsell, nm: Array<string | number>){
        let formData:FormData = new FormData();

        formData.append('active',body.active.toString());
        if(body.name){
            formData.append('name',body.name);
        }
        if(body.description){
            formData.append('description',body.description);
        }
        if(body.minSeatsToShow){
            formData.append('minSeatsToShow', `${body.minSeatsToShow}`);
        }
        if(body.goal){
            formData.append('goal', `${body.goal}`);
        }
        if(nm){
            if(nm.length){
                nm.forEach(i=>{
                    formData.append('event[]',i.toString())
                })
            }else{
                formData.append('event[]','')

            }
        }
        return this.http.patch<any>(environment.apiURl+`${this.apiRoot}/${body.id}`,formData);
    }

}
