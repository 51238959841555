import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationModule } from '../layout/pagination/pagination.module';
import { ViewComponent } from './modals/view/view.component';
import { EventsComponent } from './events.component';
import { EditComponent } from './modals/edit/edit.component';
import { ModalEventSelectorModule } from '../layout/modal-event-selector/modal-event-selector.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    EventsComponent,
    ViewComponent,
    EditComponent
  ],
  imports: [
    CommonModule,
    PaginationModule,
    ModalEventSelectorModule,
    FormsModule
  ],
  exports: [
    EventsComponent
  ]
})
export class EventsModule { }
