import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CreateComponent } from './modals/create/create.component';
import { DeleteComponent } from './modals/delete/delete.component';
import { EditComponent } from './modals/edit/edit.component';
import { Upgrade } from './models/upgrade.model';
import { UpgradeService } from './services/upgrade.service';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnInit {
  public data: Array<Upgrade>;
  public total_items_in_db: number;
  public items_per_page: number;
  private bsModalRef?: BsModalRef;
  public current_page: number;
  public refreshing: boolean;
  public subscriptions: Array<Subscription>;
  public refreshPages: boolean = false;
  @ViewChild("pagination") pagination: any;

  constructor(private modalService: BsModalService,
              private upgradeService: UpgradeService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.data = [];
    this.subscriptions = [];
    this.refreshing = false;
    this.total_items_in_db = 0;
    this.items_per_page = 0;
    this.current_page = this.activatedRoute.snapshot.queryParams['page'];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o=>o.unsubscribe())
  }
  ngOnInit(): void {
    let resolved = this.activatedRoute.snapshot.data['api'];
    this.data = resolved.data;
    this.total_items_in_db = resolved.meta.total;
    this.items_per_page = resolved.meta.per_page;
    this.current_page = 1;

    const queryparams$ = this.activatedRoute.queryParams.subscribe(params => {
      let resolved = this.activatedRoute.snapshot.data['api'];

      if(!params['page'] || params['page']>resolved.meta.last_page){
        this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: {page:1}});
      }

      if(params['page']){

        this.current_page = params['page'];
        this.data = resolved.data;
        this.total_items_in_db = resolved.meta.total;
        this.items_per_page = resolved.meta.per_page;
        if(this.pagination){
          this.pagination.refresh(this.current_page, this.total_items_in_db, this.items_per_page );
        }
      }
    });
    this.subscriptions.push(queryparams$);
  }

  public openModalDelete(id){
    const initialState: object = {
      id: id
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'delete-upsell'
    }
    this.bsModalRef = this.modalService.show(DeleteComponent, modalConfig);
    this.refreshListOnHide(1);

  }
  public openModalEdit(item: Upgrade){
    const initialState: object = {
      item: JSON.parse(JSON.stringify(item))
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'edit-upsell'
    }
    this.bsModalRef = this.modalService.show(EditComponent, modalConfig);
    this.refreshListOnHide(this.current_page);
  }

  public openModalCreate(){
    const initialState: object = {};
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'create-upsell'
    }
    this.bsModalRef = this.modalService.show(CreateComponent, modalConfig);
    this.refreshListOnHide(1);
  }
  private refreshListOnHide(page?){
    const onH$ = this.bsModalRef.onHide.subscribe((response)=>{
      if(this.bsModalRef.content.closeReason == 'success'){
        this.refreshing = true;
          this.getall(page);
      }
    })
    this.subscriptions.push(onH$);
  }

  public toggleActive(item: Upgrade){
    item.active = !item.active;
    const update$ = this.upgradeService.update$(item,null,[]).subscribe(res=>{
      console.log(res);
    });
    this.subscriptions.push(update$);
  }

  private getall(page?){
    if(!page) page = this.current_page;

    const all$ = this.upgradeService.getAll$(this.current_page).subscribe(
      {
        next:(res: any)=>{
          this.refreshing = false;
          this.data = res.data;
          this.total_items_in_db = res.meta.total;
          this.items_per_page = res.meta.per_page;
          if(this.pagination){
            this.pagination.refresh(this.current_page,this.total_items_in_db,this.items_per_page);
          }
          if(this.current_page>res.meta.last_page){
            this.current_page =page;
          }
          this.router.navigate(['/private/upgrade'],{ queryParams: {page:this.current_page}});
        },
        error: (err)=>{
          console.log(err);
        }
    });
    this.subscriptions.push(all$);
  }
  public pageChanged($event){
    this.router.navigate(['/private/upgrade'],{ queryParams: {page:$event.page}});
  }
}
