import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthUserModel, LoginBody } from "../models/auth-user.model";
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService{
    
    apiRoot = "/bo/login";
    private user: AuthUserModel;
    private userLogged$: BehaviorSubject<AuthUserModel>;

    constructor(private http: HttpClient){
        this.userLogged$ = new BehaviorSubject<AuthUserModel>(undefined);
    }

    public login(login: LoginBody){
        const body = {
            username: login.username,
            password: login.password
        };

        return this.http.post(environment.apiURl+this.apiRoot, body)
        .pipe(
            tap((result:any)=>{
                const res: AuthUserModel = {
                    token: result.jwt.token,
                    type: result.jwt.type,
                    expires_at: result.jwt.expires_at,
                    username: result.username
                }
                this.user = this.setUserLogged(res);
                this.userLogged$.next(this.user);
            }),
            
        ) as Observable<any>;
        
    }
    public logout(){
        const body = {};
        this.http.post<any>(`${this.apiRoot}/logout`, body);
        
        this.userLogged$.next(undefined);
        localStorage.removeItem('user');
    }
    private setUserLogged(user: AuthUserModel){
        if(user.token){
            /* const expiresIn = Math.round(Date.now() + user.expires_at * 1000); */
            localStorage.setItem('user', JSON.stringify({expires_at:user.expires_at, token: user.token, type: user.type, username: user.username}));
        }
        return user;
    }
    public getUserLogged(): Observable<AuthUserModel>{
        this.checkCurrentuser();
        return this.userLogged$.asObservable();
    }

    public checkCurrentuser(){
        const item = localStorage.getItem('user');
        console.log(localStorage)
        this.user = item ? JSON.parse(item) : undefined;
        console.log(this.user)
        if(this.user !== undefined){
            const check = this.checkTokenExpiration();
            if(item){
              this.user = check == false ? JSON.parse(item) : undefined;
            }
        }
        this.userLogged$.next(this.user);
    }

    public checkTokenExpiration(){
        if (localStorage.getItem('user') === null) {
            return true;
        } else {
            const date = new Date();
            const item = localStorage.getItem('user');
            const user = JSON.parse(item);
            if(!user || date> new Date(user.expires_at)){
                localStorage.removeItem('user');
                localStorage.removeItem('path');
                return true;
            }
            return false;
        }
    }
}