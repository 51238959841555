import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import {RouterModule} from '@angular/router';
import { AdlinkModule } from './adlink/adlink.module';
import { UpsellModule } from './upsell/upsell.module';
import { UpgradeModule } from './upgrade/upgrade.module';
import { TransactionsModule } from './transactions/transactions.module';
import { EventsModule } from './events/events.module';
import { DashboardModule } from './dashboard/dashboard.module';



@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AdlinkModule,
    UpsellModule,
    UpgradeModule,
    TransactionsModule,
    EventsModule,
    DashboardModule
  ],
  exports:[
    HeaderComponent,
    SidebarComponent,
  ]
})
export class PrivateModule { }
