import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdlinkComponent } from './adlink.component';
import { DeleteComponent } from './modals/delete/delete.component';
import { CreateComponent } from './modals/create/create.component';
import { EditComponent } from './modals/edit/edit.component';
import { ModalEventSelectorModule } from '../layout/modal-event-selector/modal-event-selector.module';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from '../layout/pagination/pagination.module';
//import { PaginationModule } from 'ngx-bootstrap/pagination';



@NgModule({
  declarations: [
    AdlinkComponent,
    DeleteComponent,
    CreateComponent,
    EditComponent
  ],
  imports: [
    CommonModule,
    PaginationModule,
    ModalEventSelectorModule,
    FormsModule
  ],
  exports: [
    AdlinkComponent
  ]
})
export class AdlinkModule { }
