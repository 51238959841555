import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/private/events/models/event.model';
import { EventsService } from 'src/app/private/events/services/events.service';
import { UtilsService } from 'src/app/private/services/utils.service';
import { Upsell } from '../../models/upsell.model';
import { UpsellService } from '../../services/upsell.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public item: Upsell;
  private closeReason: 'success' | 'cancel';
  private subscriptions: Array<Subscription>;
  public eventList: {
    meta: {
      total: number
      per_page: number
      current_page: number
      last_page: number
      first_page: number
      first_page_url: string
      last_page_url: string
      next_page_url: string
      previous_page_url: any
    },
    data: Array<Event>
  };
  public hashValidator = {
    name: false,
    description: false,
    goal: false,
    minSeatsToShow: false,
  }
  public checked_events: {
    list : { [key:string]:boolean },
    selected : {
      total: number,
      ids: Array<string|number>
    }
  }

  constructor(private bsModalRef: BsModalRef,
    public utils: UtilsService,
    private modalOptions: ModalOptions,
    public eventService: EventsService,
    private upsellService: UpsellService) {
      this.subscriptions = [];
      this.eventList = {
        meta: {
          total: 0,
          per_page: 0,
          current_page: 1,
          last_page: 1,
          first_page: 1,
          first_page_url: "/?page=1",
          last_page_url: "/?page=1",
          next_page_url: null,
          previous_page_url: null
        },
        data: []
      };

  }

  ngOnInit(): void {
    this.item = this.modalOptions.initialState['item'] as Upsell;
  }

  public onDismiss(reason: 'success' | 'cancel'){
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm(){
    if(this.checkInputs(this.item)) return;
    const update$ = this.upsellService.update$(this.item, this.checked_events.selected.ids).subscribe({
      next:(response: Upsell)=>{
        this.onDismiss('success')
      },
      error: (err)=>{
        console.log(err);
      }
    });
    this.subscriptions.push(update$);

  }

  public goto(page:number){
    if(!page || page > this.eventList.meta.last_page) return;
    const s = this.eventService.getAll$(page).subscribe(res=>{
      this.eventList = res;
    });
    this.subscriptions.push(s);
  }

  public check(id){
    this.checked_events.list[id]=!this.checked_events.list[id];
    let all_clicked = Object.keys(this.checked_events.list);
    this.checked_events.selected = {ids: [], total: 0};
    all_clicked.forEach(id=>{
      if (this.checked_events.list[id]){
        this.checked_events.selected.total+=1;
        this.checked_events.selected.ids.push(id)
      }
    });
  }

  public getCheckedEvents($event){
    this.checked_events = $event;
  }

  // check specified fields and change value at hasValidator
  // help to detect wich inputs have bad values
  private checkInputs(instance: Upsell): boolean {
    let hasErrors = false
    // Reset validator on every check
    Object.keys(this.hashValidator).forEach((value) => {
      this.hashValidator[value] = false;
    })
    if (this.utils.isEmpty(instance.name)) {
      this.hashValidator.name = true;
      hasErrors = true
    }
    if (this.utils.isEmpty(instance.description)) {
      this.hashValidator.description = true;
      hasErrors = true
    }
    if (instance.minSeatsToShow <= 0) {
      this.hashValidator.minSeatsToShow = true;
      hasErrors = true
    }
    if(instance.goal <= 0) {
      this.hashValidator.goal = true
      hasErrors = true
    }

    return hasErrors
  }
}
