import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DashboardService{

    private apiRoot = '/bo/diagnostic/';

    constructor(private http: HttpClient){

    }

    public getAnalyticsEventsTransactions$(page?:number): Observable<any>{
        let query = '';
        let endpoint = 'events/transactions';
        if(page){
            query='?limit='+page.toString();
        }
        return this.http.get<any>(environment.apiURl+`${this.apiRoot}${endpoint}${query}`);
    }

    public getAnalyticsEventsTransactionsByCodes$(codes: Array<string>): Observable<any>{
        let endpoint = 'events/transactions/codes';
        let body = { codes }
        return this.http.post<any>(environment.apiURl+`${this.apiRoot}${endpoint}`,body);
    }

}
