import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Transaction, TransactionStatus, TransactionStatusHashed, TransactionStatusObj } from '../../models/transaction.model';
import { TransactionService } from '../../services/transactions.service';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  private subscriptions : Array<Subscription>;
  public data: Transaction;
  public transactionStatus : TransactionStatusObj;

  constructor(private bsModalRef: BsModalRef,    public modalOptions: ModalOptions, private transactionsService: TransactionService ) {
    this.subscriptions=[];
  }

  ngOnInit(): void {
    if(this.modalOptions && this.modalOptions.initialState){
      this.data = this.modalOptions.initialState['item'] as Transaction;
      this.transactionStatus = this.modalOptions.initialState['transactionStatus'] as TransactionStatusObj;
      if(typeof this.data.seats === 'string'){
        let seats = this.data.seats.split(";").map(s=>{
          return s.split("S_")[1].split("-")
        });
        this.data.seats = seats;
      }
    }
  }
  ngOnDestroy(): void{
    this.subscriptions.forEach(o=>o.unsubscribe())
  }
  public onDismiss(){
    this.bsModalRef.hide();
  }

  public onConfirm(){
    this.bsModalRef.hide();

  }
}
