import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Upsell } from './models/upsell.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CreateComponent } from './modals/create/create.component';
import { EditComponent } from './modals/edit/edit.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UpsellService } from './services/upsell.service';
import { DeleteComponent } from './modals/delete/delete.component';
import { PaginationComponent } from '../layout/pagination/pagination.component';

@Component({
  selector: 'app-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.scss']
})

export class UpsellComponent implements OnInit, OnDestroy {
  public data: Array<Upsell>;
  public total_items_in_db: number;
  public items_per_page: number;
  private bsModalRef?: BsModalRef;
  private subscriptions: Array<Subscription>;
  public current_page: number;
  @ViewChild('pagination') pagination: PaginationComponent;


  constructor(private modalService: BsModalService, private activatedRoute: ActivatedRoute,
    private upsellService: UpsellService,    private router: Router ) {
    this.data = []
    this.subscriptions = [];
  }

  ngOnDestroy(): void{

    this.subscriptions.forEach(o=>o.unsubscribe())
  }
  ngOnInit(): void {
      let resolved = this.activatedRoute.snapshot.data['api'];
      this.data = resolved.data;
      this.total_items_in_db = resolved.meta.total;
      this.items_per_page = resolved.meta.per_page;

      const queryparams$ = this.activatedRoute.queryParams.subscribe(params => {
        let resolved = this.activatedRoute.snapshot.data['api'];
  
        if(!params['page'] || params['page']>resolved.meta.last_page){
          this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: {page:1}});
        }
  
        if(params['page']){
  
          this.current_page = params['page'];
          this.data = resolved.data;
          this.total_items_in_db = resolved.meta.total;
          this.items_per_page = resolved.meta.per_page;
          if(this.pagination){
            this.pagination.refresh(this.current_page, this.total_items_in_db, this.items_per_page );
          }
        }
      });
      this.subscriptions.push(queryparams$);
  }

  public openModalDelete(upsellId:number){
    const initialState: object = {
      id: upsellId
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'delete-upsell'
    }
    this.bsModalRef = this.modalService.show(DeleteComponent, modalConfig);
    this.refreshListOnHide(1);

  }
  public openModalEdit(item: Upsell){
    const initialState: object = {
      item: JSON.parse(JSON.stringify(item))
    };
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'edit-upsell'
    }
    this.bsModalRef = this.modalService.show(EditComponent, modalConfig);
    this.refreshListOnHide(this.current_page);
  }

  public openModalCreate(){
    const initialState: object = {};
    const modalConfig: ModalOptions = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      initialState,
      class: 'create-upsell'
    }
    this.bsModalRef = this.modalService.show(CreateComponent, modalConfig);
    this.refreshListOnHide(1);
  }

  public toggleActive(item: Upsell){
    item.active = !item.active;
    const update$ = this.upsellService.update$(item,[]).subscribe(res=>{
      console.log(res);
    });
    this.subscriptions.push(update$);
  }

  private refreshListOnHide(page?:string|number){
    const onH$ = this.bsModalRef.onHide.subscribe((response)=>{
      if(this.bsModalRef.content.closeReason == 'success'){
        this.getall(page);

      }
    })
    this.subscriptions.push(onH$);
  }
  private getall(page?){
    if(!page) page = this.current_page;

    const all$ = this.upsellService.getAll$(page).subscribe(
      {
        next:(res: any)=>{
          this.data = res.data;
          this.total_items_in_db = res.meta.total;
          this.items_per_page = res.meta.per_page;
          if(this.pagination){
            this.pagination.refresh(this.current_page,this.total_items_in_db,this.items_per_page);
          }
          if(this.current_page>res.meta.last_page){
            this.current_page =page;
          }
          this.router.navigate(['/private/upsell'],{ queryParams: {page:this.current_page}});
        },
        error: (err)=>{
          console.log(err);
        }
    });
    this.subscriptions.push(all$);
  }

}
