import { Component, OnInit } from '@angular/core';
import { SidebarStatus } from './models/sidebar.model';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent implements OnInit {

  public sidebarStatus: SidebarStatus = {
    open : false
  }

  constructor() { }

  ngOnInit(): void {
  }


  public sidebarStatusEvent($status: SidebarStatus){
    console.log($status);
  }
}
