import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Adlink } from "../../adlink/models/adlink.model";
import { QueryParams } from "../models/event.model";

@Injectable({
    providedIn: 'root'
})
export class EventsService{

    private apiRoot = '/bo/events';

    constructor(private http: HttpClient){}

    public getAll$(page?:number,queryParams?:QueryParams): Observable<any>{
        let query = '';
        if(page){
            query='?page='+page;
        }
        if(queryParams){
            if(queryParams.future) query+="&future=1"
        }
        return this.http.get<any>(environment.apiURl+`${this.apiRoot}${query}`);
    }
    public getAllPageLess$(): Observable<any>{
        let query = '?page=1&limit=9999';
        return this.http.get<any>(environment.apiURl+`${this.apiRoot}${query}`);
    }
    public refresh$(): Observable<any>{
        return this.http.get<any>(environment.apiURl+`${this.apiRoot}/import`);
    }
    public get$(id:number): Observable<any>{
        return this.http.get<any>(environment.apiURl+`${this.apiRoot}/${id}`);
    }
    public searchEvent$(id: string): Observable<any> {
      let params = new HttpParams().set('search', id);
      return this.http.get<any>(environment.apiURl+`${this.apiRoot}/`, { params });
    }
    public update$(id:number,nmEntity:string, nm:Array<string|number>): Observable<any>{
        let formData:FormData = new FormData();
        if(nm){
            if(nm.length){
                nm.forEach(i=>{
                    formData.append(nmEntity,i.toString())
                })
            }else{
                formData.append(nmEntity,'')

            }
        }
        return this.http.patch<any>(environment.apiURl+`${this.apiRoot}/${id}`,formData);
    }

    public updateAdlink$(id: number, adlink: number): Observable<any> {
      return this.http.patch<Event>(environment.apiURl+`${this.apiRoot}/${id}`, { adlinkId: adlink });
    }

    public updateDetails$(id: number, details: any, file?: File) {
      const body = {
        minTickets: details.minTickets,
        maxTickets: details.maxTickets,
        defaultBuyerType: details.defaultBuyerType,
        defaultBuyerTypeName: details.defaultBuyerTypeName
      }
      let formData = new FormData();
      let options = {};
      if (file) {
        formData.append('image', file, file.name);
      }
      if (details.minTickets) {
        formData.append('minTickets', details.minTickets);
      }
      if (details.maxTickets) {
        formData.append('maxTickets', details.maxTickets);
      }
      if (details.extraText) {
        formData.append('extraText', details.extraText);
      }
      if (details.defaultBuyerType) {
        formData.append('defaultBuyerType', details.defaultBuyerType)
      }
      if (details.defaultBuyerTypeName) {
        formData.append('defaultBuyerTypeName', details.defaultBuyerTypeName)
      }
      if(details.urlImage == null) {
        formData.append('urlImage', '');
      }else{
          formData.append('urlImage', details.urlImage);
      }


      return this.http.patch<any>(environment.apiURl+`${this.apiRoot}/${id}`, formData, options);
    }
}
