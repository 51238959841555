import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/private/events/models/event.model';
import { EventsService } from 'src/app/private/events/services/events.service';
import { CheckedEvents } from 'src/app/private/layout/modal-event-selector/models/checkedevents.model';
import { UtilsService } from 'src/app/private/services/utils.service';
import { Upsell } from '../../models/upsell.model';
import { UpsellService } from '../../services/upsell.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnDestroy {

  public item: Upsell;
  private closeReason: 'success' | 'cancel';

  public subscriptions: Array<Subscription>;
  private checked_events: CheckedEvents;
  public hashValidator = {
    name: false,
    description: false,
    goal: false,
    minSeatsToShow: false,
  }

  constructor(private bsModalRef: BsModalRef, private upsellService: UpsellService,
    public utils: UtilsService,
    public eventService: EventsService) {
    this.item = {
      active: true,
      name: '',
      description: '',
      minSeatsToShow: 1,
      goal: 1,
      events: []
    }
    this.subscriptions = [];

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void{
    this.subscriptions.forEach(o=>o.unsubscribe());
  }

  public onDismiss(reason: 'success' | 'cancel'){
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm(){
    // validamos los inputs utilizando el objeto enlazado por ngModel (two data binding)
    if(this.checkInputs(this.item)) return;
    this.upsellService.create$(this.item, this.checked_events.selected.ids).subscribe({
      next:(response: Upsell)=>{
        this.onDismiss('success')
      },
      error: (err)=>{
        console.log(err);
      }
    });

  }

  public getCheckedEvents($event){
    this.checked_events = $event;
  }

  // check specified fields and change value at hasValidator
  // help to detect wich inputs have bad values
  private checkInputs(instance: Upsell): boolean {
    let hasErrors = false
    // Reset validator on every check
    Object.keys(this.hashValidator).forEach((value) => {
      this.hashValidator[value] = false;
    })
    if (this.utils.isEmpty(instance.name)) {
      this.hashValidator.name = true;
      hasErrors = true
    }
    if (this.utils.isEmpty(instance.description)) {
      this.hashValidator.description = true;
      hasErrors = true
    }
    if (instance.minSeatsToShow < 0) {
      this.hashValidator.minSeatsToShow = true;
      hasErrors = true
    }
    if(instance.goal < 0) {
      this.hashValidator.goal = true
      hasErrors = true
    }

    return hasErrors
  }

}
