<h1>Transactions Management</h1>
<div class="topbar">
    <div class="topbar-side">
        <div class="topbar-top">
            <div class="topbar-item">
                <div>
                    <span>Events</span>
                </div>
                <div *ngIf="eventList.length">
                    <ng-multiselect-dropdown
                        [placeholder]="'Select Event'"
                        [settings]="dropdownSettings"
                        [(ngModel)]="selectedItems"
                        [data]="eventList"
                        (onSelect)="ondropdownSelect($event)">
                        (onUnSelect)="ondropdownUnSelect($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="topbar-sub">
            <div id="topbar-status"  class="topbar-item">
                <div>
                    <span>Status</span>
                </div>
                <div>
                    <select  [(ngModel)]="search.params.status">
                        <option [value]="''">All</option>
                        <option [value]='item.id' *ngFor="let item of transactionsStatus.list">
                            {{item.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="topbar-item">
                <div>
                    <span>Account</span>
                </div>
                <div>
                    <input type='text' [(ngModel)]="search.params.accountId">
                </div>
            </div>
            <div class="topbar-item">
                <div>
                    <span>Full name</span>
                </div>
                <div>
                    <input type='text' [(ngModel)]="search.params.fullName">
                </div>
            </div>
            
            <div id="topbar-from" class="topbar-item">
                <div>
                    <span>From</span>
                </div>
                <div>
                    <input type='date' [(ngModel)]="search.params.dateFrom">
                </div>
            </div>
            <div id="topbar-to"  class="topbar-item">
                <div>
                    <span>To</span>
                </div>
                <div>
                    <input type="date" [(ngModel)]="search.params.dateTo">
                </div>
            </div>
        </div>
    </div>
    <div class="topbar-side">
        <div id='topbar-btns' class="topbar-item">
            <button class="default-1 me-2" (click)="submit()">
                <i class="ti ti-search"></i>
            </button>
            <button class="default-2" (click)="reset()" *ngIf="search.applied">
                Clear
            </button>
        </div>
    </div>
</div>
<div class="default-table">

    <table>
        <thead>
            <tr>
                <th>Status</th>
                <th>Account #</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Date</th>
                <th>Event</th>
                <th>Balance</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let i of data">
                <td> {{transactionsStatus.items[i.status.toUpperCase()].name}}</td>
                <td> {{i.patronId}}</td>
                <td> {{i.patronFullName}}</td>
                <td> {{i.patronEmail}}</td>
                <td> {{i.created_at | date }}</td>
                <td> {{i.event.description}} / {{i.event.code}}</td>
                <td>  $ {{i.tdc_transaction?.balance}} </td>
                <td>
                    <button class="default-2" (click)="openModalEdit(i)">
                        Details
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <p class="legend">
        Showing {{data.length}} of {{total_items_in_db}} total Transactions
    </p>
</div>
<app-pagination [currentPage]='current_page' 
                [total_items_in_db]="total_items_in_db"
                [items_per_page]="items_per_page"
                #pagination
                ></app-pagination>