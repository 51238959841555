import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SearchQueryParams } from "../models/transaction.model";

@Injectable({
    providedIn: 'root'
})
export class TransactionService{

    private apiRoot = '/bo/transactions';
    public search: SearchQueryParams;
    constructor(private http: HttpClient){}

    public getAll$(page?:number, searchParams?:SearchQueryParams): Observable<any>{
        let query = '';
        if(page){
            query='?page='+page;
        }
        if(searchParams){
            if(searchParams.params.status) query+='&status='+searchParams.params.status;
            if(searchParams.params.accountId) query+='&accountId='+searchParams.params.accountId;
            if(searchParams.params.fullName) query+='&fullName='+searchParams.params.fullName;
            if(searchParams.params.dateFrom) query+='&dateFrom='+searchParams.params.dateFrom;
            if(searchParams.params.dateTo) query+='&dateTo='+searchParams.params.dateTo;
            if(searchParams.params.event) query+='&event='+searchParams.params.event;
        }
        return this.http.get<any>(environment.apiURl+`${this.apiRoot}${query}`); 
    }
    public get$(id?:number): Observable<any>{
        return this.http.get<any>(environment.apiURl+`${this.apiRoot}/${id}`); 
    }
}