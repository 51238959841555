

<div class="page">
    <div class="page-content">
        <div class="header">
            <div class="header-wrapper">
                <div class="header-wrapper-col">
                    <img src="/assets/img/logo.svg" alt="Chicago Cubs logo">
                    <h1>Chicago Cubs Group Sales</h1>
                </div>
            </div>
        </div>
        <div class="page-component">
            <h1>Private Area</h1>
            <form #form="ngForm" (ngSubmit)="submit(form)">
              <div class="input-line">
                  <div>
                      <span>Username</span>
                  </div>
                  <div>
                      <input name="username" [(ngModel)]="loginBody.username" type="text">
                  </div>
              </div>
              <div class="input-line">
                  <div>
                      <span>Password</span>
                  </div>
                  <div>
                      <input [(ngModel)]="loginBody.password" name="password" type="password">
                  </div>
              </div>
              <div class="alert alert-danger" role="alert" *ngIf="error">
                  Something went wrong please try again or contact support@3ddigitalvenue.com
              </div>
              <button type="submit" class="default-1">LOGIN</button>
            </form>
        </div>
    </div>
</div>
