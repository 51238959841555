import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PrivateComponent } from './private/private.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PrivateModule } from './private/private.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpBearerInterceptor } from './private/interceptors/bearer-token.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    PrivateComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    PrivateModule,
    ModalModule.forRoot()
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpBearerInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
