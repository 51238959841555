<div class="modal-container">
    <div class="data-container">
        <div class="title-wrapper">
            <p>Are you sure you want to delete this AdLink?</p>
            <button type="button" class="btn-modal-header" (click)="onDismiss('cancel')" data-dismiss="modal">
                <span class='ti ti-close'></span>
            </button>
        </div>
        <div class="content-wrapper">
            <div class="mt-4">
                <div  class="input-line">
                    <div>
                        <span><b>Image</b></span>
                    </div>
                    <div>
                        <div *ngIf="data?.url_image else noimage">
                            <img [src]="data.url_image">
                        </div>
                        <ng-template #noimage>
                            No image
                        </ng-template>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Name</b></span>
                    </div>
                    <div>
                        <p>{{data?.name}}</p>
                    </div>
                </div>
                <div class="input-line">
                    <div>
                        <span><b>Title</b></span>
                    </div>
                    <div>
                        <p>{{data?.title}}</p>
                    </div>
                </div>
                <div class="input-line">
                    <div>
                        <span><b>Link</b></span>
                    </div>
                    <div>
                        <p>{{data?.link}}</p>
                    </div>
                </div>
                <div class="input-line">
                    <div>
                        <span><b>Visibility</b></span>
                    </div>
                    <div>
                        <p>{{data?.active}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="btns-containers">
            <button class="default-4" (click)="onDismiss('cancel')">Cancel</button>
            <button class="default-1" (click)="onConfirm(data.id)">Permanently delete</button>
        </div>
    </div>
</div>