<div class="modal-container">
    <div class="data-container">
        <div class="title-wrapper">
            <p>Create new AdLink</p>
            <button type="button" class="btn-modal-header" (click)="onDismiss('cancel')" data-dismiss="modal">
                <span class='ti ti-close'></span>
            </button>
        </div>
        <div class="content-wrapper">
            <div class="mt-4 mb-0">
                <div  class="input-line input-file-line">
                    <div>
                        <span><b>Add new image</b></span><br>
                        <span><small>(transparent png recommended)</small></span>
                    </div>
                    <div class="input-file-wrapper">
                        <button class="default-2" (click)="addfile.click()">Upload Image</button>
                        <div class="img-empty-container" *ngIf="!imagePreview else preview">
                            Preview
                        </div>
                        <ng-template #preview>
                            <img [src]='imagePreview'>
                        </ng-template>
                        <input type="file" class="d-none" #addfile (change)="onFileChange($event)">

                    </div>
                </div>
            </div>
        </div>
        <div class="content-wrapper">

            <div>
                <div class="input-line">
                    <div>
                        <span><b>Name</b></span>
                    </div>
                    <div>
                        <input type="text" [(ngModel)]="item.name" [class.invalid-input]="hashValidator.name">
                        <span class="small error-color" *ngIf="hashValidator.name">The entry is mandatory</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Title</b></span>
                    </div>
                    <div>
                        <textarea [(ngModel)]="item.title" [class.invalid-input]="hashValidator.title"></textarea>
                        <span class="small error-color" *ngIf="hashValidator.title">The entry is mandatory</span>
                    </div>
                </div>
                <div  class="input-line">
                    <div>
                        <span><b>Link</b></span>
                        <a *ngIf="previewlink" [href]=previewlink class="text-decoration-none" target="_blank">
                            <i class="ms-4 ti ti-link"></i> <small>Preview</small>
                        </a>
                    </div>
                    <div>
                        <div class="d-flex align-items-center">
                            <small class="linkside c-black" *ngIf="!hideProtocol">https://</small>
                            <input class="link" type="text" [(ngModel)]="item.link" (ngModelChange)="linkChanged($event)"  [class.invalid-input]="hashValidator.link">
                        </div>
                    </div>
                    <div>
                      <span class="small error-color" *ngIf="hashValidator.link">The entry is not valid</span>
                    </div>
                </div>
                <div class="input-line">
                    <div>
                        <span>
                            <b>Visibility</b>
                        </span>
                    </div>
                    <div>
                        <select [(ngModel)]="item.active">
                            <option value="true">On</option>
                            <option value="false">Off</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="ms-4">
                <app-modal-event-selector (checkedevents)="getCheckedEvents($event)" [mode]="'multiple'"></app-modal-event-selector>
            </div>
        </div>
        <div class="btns-containers">
            <button class="default-4" (click)="onDismiss('cancel')">Cancel</button>
            <button class="default-1" (click)="onConfirm()">Save & Publish</button>
        </div>
    </div>
</div>
