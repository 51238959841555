import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class HttpBearerInterceptor implements HttpInterceptor {

    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headerName = '';
        let user;
        if (localStorage.getItem('user') !== null) {
            user = JSON.parse(localStorage.getItem('user'));
            if (user.token !== null && !req.headers.has(headerName)) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${user.token}`
                    }
                });
            }
        }
        return next.handle(req);
    }
}