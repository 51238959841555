<div class="modal-container">
    <div class="data-container">
        <div class="title-wrapper">
            <p>Edit Event <span class="text-capitalize">{{type}}</span></p>
            <button type="button" class="btn-modal-header" (click)="onDismiss('cancel')" data-dismiss="modal">
                <span class='ti ti-close'></span>
            </button>
        </div>
        <div class="content-wrapper" *ngIf="event">
            <div class="mt-4">
                <div  class="input-line">
                    <div>
                        <span><b>Event</b></span>
                    </div>
                    <div class="input-line-value">
                        <span>{{event.code}} : {{event.description}}</span>
                    </div>
                    <div [ngSwitch]="type" class="mt-3">
                        <div *ngSwitchCase="'upgrade'">
                            <span><b>Select from the Upgrades List</b></span>
                            <div class="input-check-lines-wrapper">
                                <div class="input-check-line" *ngFor="let item of upgradeList?.data" (click)="check(item.id)">
                                    <input type="checkbox" [(ngModel)]="checked.list[item.id]">
                                    <img [src]="item.url_image">
                                    <span>{{item.name}} <br> {{item.description}}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'upsell'">
                            <span><b>Select from the Upsell List</b></span>
                            <div class="input-check-lines-wrapper">
                                <div class="input-check-line" *ngFor="let item of upsellList?.data" (click)="check(item.id)">
                                    <input type="checkbox" [(ngModel)]="checked.list[item.id]">
                                    <span>{{item.name}} <br> {{item.description}}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'adlink'">
                            <span><b>Select 1 AdLink</b></span>
                            <div class="input-check-lines-wrapper">
                                <div class="input-check-line" *ngFor="let item of adLinkList?.data" (click)="check(item.id)">
                                    <input type="checkbox" [(ngModel)]="checked.list[item.id]">
                                    <img [src]="item.url_image">
                                    <span>{{item.name}} <br> {{item.title}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btns-containers">
            <button class="default-4" (click)="onDismiss('cancel')">Cancel</button>
            <button class="default-1" (click)="onConfirm()">Save & Publish</button>
        </div>
    </div>
</div>