import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './private/dashboard/dashboard.component';
import { AuthGuard } from './private/private.guard';
import { LoginComponent } from './login/login.component';
import { PrivateComponent } from './private/private.component';
import { AdlinkComponent } from './private/adlink/adlink.component';
import { TransactionsComponent } from './private/transactions/transactions.component';
import { UpgradeComponent } from './private/upgrade/upgrade.component';
import { UpsellComponent } from './private/upsell/upsell.component';
import { EventsPageLessResolver, TransactionsResolver } from './private/transactions/resolvers/transactions.resolver';
import { UpgradeResolver } from './private/upgrade/resolvers/upgrade.resolver';
import { UpsellResolver } from './private/upsell/resolvers/upsell.resolver';
import { EventsComponent } from './private/events/events.component';
import { EventsResolver } from './private/events/resolvers/events.resolver';
import { AdlinkResolver } from './private/adlink/resolvers/adlink.resolver';
import { DashboardResolver } from './private/dashboard/resolvers/dashboard.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/private/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'private',
    component: PrivateComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        resolve: {
          api: DashboardResolver,
          events: EventsPageLessResolver
        },
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: TransactionsResolver,
          events: EventsPageLessResolver
        },
      },
      {
        path: 'upgrade',
        component: UpgradeComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: UpgradeResolver
        },
      },
      {
        path: 'upsell',
        component: UpsellComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: UpsellResolver,
        },
      },
      {
        path: 'events',
        component: EventsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: EventsResolver,
        },
      },
      {
        path: 'adlink',
        component: AdlinkComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          api: AdlinkResolver,
        },
      }
    ],
  },
  {
    path:'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: '/login'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
