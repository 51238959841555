import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Adlink } from '../../models/adlink.model';
import { AdlinkService } from '../../services/adlink.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {
  public data: Adlink;
  private closeReason: 'success' | 'cancel';
  private subscriptions: Array<Subscription>;
  private waitingapi:boolean;
  constructor(private bsModalRef: BsModalRef,
    private adlinkService: AdlinkService,
    public modalOptions: ModalOptions ) {
    this.subscriptions = [];
    this.waitingapi = false;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(o=>o.unsubscribe())
  }
  ngOnInit(): void {
    if(this.modalOptions && this.modalOptions.initialState){
      let id = this.modalOptions.initialState['id']
      console.log(id);
      if(typeof id === 'string'){
        id = parseInt(id);
      }
      if(typeof id === 'number'){
          const g$ = this.adlinkService.get$(id).subscribe({
            next:(response: Adlink)=>{
              this.data = response;
              console.log(this.data);
            },
          error: (err)=>{
            console.log(err);
          }
        });
        this.subscriptions.push(g$);
      }
    }
  }
  public onDismiss(reason: 'success' | 'cancel'){
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm(id: number){
    if(this.waitingapi) return;
    this.waitingapi = true;
    this.adlinkService.delete$(id).subscribe({
      next:(response: Adlink)=>{
        this.onDismiss('success')
      },
      error: (err)=>{
        console.log(err);
      }
    });
  }
}
