import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription, take } from 'rxjs';
import { Event } from '../../models/event.model';
import { EventsService } from '../../services/events.service';
import { UtilsService } from "../../../services/utils.service";

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  private subscriptions : Array<Subscription>;
  public data: Event;
  public dataCopy: Event;
  public result: Event;
  public hashValidator = {
    buyerTypeName: false,
    buyerTypeId: false,
    minTickets: false,
    maxTickets: false,
  }
  public postFile: HTMLInputElement;
  public postImage: File;
  public imagePreview: any;

  constructor(private bsModalRef: BsModalRef, private utils: UtilsService,
              public modalOptions: ModalOptions, private eventService: EventsService ) {
    this.subscriptions=[];
  }

  ngOnInit(): void {
    if(this.modalOptions && this.modalOptions.initialState){

      let eventid = this.modalOptions.initialState['eventid']
      if(typeof eventid === 'string'){
        eventid = parseInt(eventid);
      }
      if(typeof eventid === 'number'){
        const g$ = this.eventService.get$(eventid).subscribe({
          next:(response: Event)=>{
            this.data = response;
            this.dataCopy = response;
            console.log(this.data);
          },
          error: (err)=>{
            console.log(err);
          }
        });
        this.subscriptions.push(g$);
      }
    }
  }
  ngOnDestroy(): void{
    this.subscriptions.forEach(o=>o.unsubscribe())
  }
  public onDismiss(){
    this.bsModalRef.hide();
  }

  public onConfirm(){
    let hasErrors = this.checkInputs(this.data)
    if (hasErrors) return;
    let subs = this.eventService.updateDetails$(this.data.id, this.data, this.postImage).pipe(take(1)).subscribe((response) => {
      subs.unsubscribe()
      this.result = response
      this.bsModalRef.hide();
    })
  }

  // check specified fields and change value at hasValidator
  // help to detect wich inputs have bad values
  private checkInputs(instance: Event): boolean {
    let hasErrors = false
    Object.keys(this.hashValidator).forEach((key) => this.hashValidator[key] = false)
    if (this.utils.isEmpty(instance.defaultBuyerTypeName)) {
      this.hashValidator.buyerTypeName = true
      hasErrors = true
    } else {
      this.hashValidator.buyerTypeName = false
    }
    if (!this.utils.onlyHasNumbers(instance.defaultBuyerType)) {
      this.hashValidator.buyerTypeId = true
      hasErrors = true
    } else {
      this.hashValidator.buyerTypeId = false
    }
    if (instance.maxTickets < 0) {
      this.hashValidator.maxTickets = true
      hasErrors = true
    }
    if (instance.minTickets < 0) {
      this.hashValidator.minTickets = true
      hasErrors = true
    }


    return hasErrors
  }

  public onFileChange(event){
    this.postImage = event.target.files[0];
    var reader = new FileReader();
    this.imagePreview = null;
    reader.readAsDataURL(event.target.files[0]);
		reader.onload = (_event) => {
			this.imagePreview = reader.result;
		}
  }

  public removeImage(){
    this.data.urlImage = null
    this.postImage = null
    this.imagePreview = null
  }
}
