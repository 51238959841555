import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventsService } from '../../events/services/events.service';
import { TransactionService } from '../services/transactions.service';
@Injectable({
  providedIn: 'root',
})
export class TransactionsResolver implements Resolve<any> {
  constructor(
    private transactionsService: TransactionService,
    private router: Router
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let page = 1;
    if (route.queryParams['page']){
      page = route.queryParams['page'];
    }
    let extraSearch = this.transactionsService.search;
    return this.transactionsService.getAll$(page,extraSearch).pipe(
      map((list) => list),
      catchError((error) => {
        this.router.navigate(['/dashboard']);
        return of({ list: null, error: error });
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class EventsPageLessResolver implements Resolve<any> {
  constructor(
    private eventsService: EventsService,
    private router: Router
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.eventsService.getAllPageLess$().pipe(
      map((list) => list),
      catchError((error) => {
        this.router.navigate(['/dashboard']);
        return of({ list: null, error: error });
      })
    );
  }
}