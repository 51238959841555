import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionsComponent } from './transactions.component';
import { PaginationModule } from '../layout/pagination/pagination.module';
import { ViewComponent } from './modals/view/view.component';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';



@NgModule({
  declarations: [
    TransactionsComponent,
    ViewComponent
  ],
  imports: [
    CommonModule,
    PaginationModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    TransactionsComponent
  ]
})
export class TransactionsModule { }
